.mainContainerNewInput {
    background-color:#f7f7f7;
    padding-left: 60px;
    padding-right: 60px;
}
.wrapper-class {
    padding-bottom: 10px;
    padding-top: 10px;
    /* border: 1px solid #ccc; */
  }
  .editor-class {
    padding: 1em;
    border: 1px solid #ccc;
    background-color: #ffffff;
  }
  .toolbar-class {
    border: 1px solid #ccc;
    width: 100%;
  }
  .delButton {
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .headline {
    padding: 20px;
    font-weight: bold;
  }